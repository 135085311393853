import React from "react";
import { useDispatch, useSelector } from "react-redux";
import groupBy from "lodash.groupby";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import useGoTo from "../hooks/useGoTo";
import {
  getGroupAvailability,
  getProductAvailability,
} from "../hooks/useWorkingTime";
import { Button, Figure, Row } from "react-bootstrap";
import ProductsListTotem from "../components/ProductsListTotem";
import FloatingCartSummary from "./FloatingCartSummary";
import { elementScrollTo } from "../utils/domScrollTo";
import { setActiveGroupId } from "../ducks/productsSlice";
import ScrollButton from "../components/ScrollButton";
import RequestWaiterButton from "../components/RequestWaiterButton";

export default function GroupedProductsTotemView({ isCartVisible }) {
  const dispatch = useDispatch();
  const goTo = useGoTo();

  const {
    id: shopId,
    tableId,
    pictureUrl,
    inLocoText,
  } = useSelector((state) => state.shop);

  const {
    activeGroupId,
    groups,
    items: products,
  } = useSelector((state) => state.products);

  const availableProducts = products.filter(
    (p) => getProductAvailability(p).isAvailable
  );

  const filteredProductsByGroup = groupBy(availableProducts, "groupId");

  const onProductSelect = (productId) => goTo(`/product/${productId}`);

  const availableGroups = groups.filter(
    (g) => filteredProductsByGroup[g.id] && getGroupAvailability(g).isAvailable
  );

  const activeGroup =
    groups.find((g) => g.id === activeGroupId) || availableGroups[0];

  const handleGroupClick = (group) => (event) => {
    elementScrollTo("totem-products-column", 0, "auto");
    dispatch(
      setActiveGroupId({
        activeGroupId: group.id,
      })
    );
  };

  const handleVerConsumoClick = (event) => goTo(`/status-inloco`);

  return (
    <div className="totem-root-div">
      <ScrollButton elementId="totem-products-column" />
      <Row className="p-0 m-0">
        <div className="totem-home-groups-column btn-shop">
          <Figure.Image
            src={pictureUrl}
            alt="Logo da loja"
            className="mx-2 my-0"
          />
          {inLocoText && (
            <p className={`totem-group-name text-center`}>
              <strong>{inLocoText}</strong>
            </p>
          )}

          {availableGroups.map((group) => (
            <div
              className="my-2"
              key={group.id}
              onClick={handleGroupClick(group)}
            >
              <div className="d-flex justify-content-center align-items-end rounded-circle mx-1">
                <div
                  className="rounded-circle totem-group-image"
                  style={{
                    backgroundImage: `url("${group.image}")`,
                  }}
                />
              </div>
              <p
                className={`m-0 totem-group-name text-center mt-0 mb-2 ${
                  group.id === activeGroupId ? "text-selected" : ""
                }`}
              >
                {group.name}
              </p>
            </div>
          ))}

          {inLocoText && (
            <>
              <Button
                className="p-2 m-2"
                variant="shop-tablet"
                onClick={handleVerConsumoClick}
              >
                <ShoppingCartIcon /> Ver Consumo
              </Button>
              <RequestWaiterButton shopId={shopId} tableId={tableId} />
            </>
          )}
        </div>
        <div id="totem-products-column" className="totem-home-products-column">
          {activeGroup && (
            <div id="totem-products" className="totem-home-column">
              <div
                className={`shop-home-screen--${
                  isCartVisible ? "stretched" : "normal"
                }`}
              >
                <ProductsListTotem
                  group={activeGroup}
                  products={filteredProductsByGroup[activeGroup.id]}
                  onProductSelect={onProductSelect}
                />
              </div>
              {isCartVisible && <FloatingCartSummary />}
            </div>
          )}
        </div>
      </Row>
    </div>
  );
}
