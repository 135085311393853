import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import localPersistence from "redux-persist/lib/storage";
import appState from "./ducks/appStateSlice";
import shop from "./ducks/shopSlice";
import user from "./ducks/userSlice";
import products from "./ducks/productsSlice";
import shoppingCart from "./ducks/shoppingCartSlice";
import checkout from "./ducks/checkoutSlice";
import search from "./ducks/searchSlice";
import marketplace from "./ducks/marketplaceSlice";

const checkoutPersistConfig = {
  key: "checkout",
  storage: localPersistence,
  blacklist: ["inLocoLocation", "submitError", "cardCVV"],
};

const cartPersistConfig = {
  key: "shoppingCart",
  storage: localPersistence,
};

const shopPersistConfig = {
  key: "shop",
  storage: localPersistence,
  whitelist: ["token", "user18Older"],
};

const userPersistConfig = {
  key: "user",
  storage: localPersistence,
};

const rootReducer = combineReducers({
  checkout: persistReducer(checkoutPersistConfig, checkout),
  shoppingCart: persistReducer(cartPersistConfig, shoppingCart),
  appState,
  shop: persistReducer(shopPersistConfig, shop),
  user: persistReducer(userPersistConfig, user),
  products,
  search,
  marketplace,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export function getState() {
  return store.getState();
}

window.store = store; // for devs! 😉
