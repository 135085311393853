import { createSlice } from "@reduxjs/toolkit";
import {
  sliceFirebaseShop,
  sliceFirebaseGroups,
  sliceFirebaseProducts,
} from "../utils/firebase-adapters";
import { minimalDelayedHOC } from "../utils/minimalDelayedHOC";
import { setShopDetails, setOrderAvailability } from "./shopSlice";
import { loadMarketplace } from "./marketplaceSlice";
import { setGroupedProductsWithQuestions } from "./productsSlice";
import { requireProducts } from "../ducks/productsSlice";
import { refreshCachedCosts } from "../ducks/shoppingCartSlice";
import { validateCache } from "../ducks/checkoutSlice";
import { STATE_STEP } from "./consts";
import getFirebaseData from "../utils/firebase-get-data";

export const appStateSlice = createSlice({
  name: "appState",
  initialState: {
    namespace: "",
    showOnlyStatus: false,
    step: STATE_STEP.INITIAL,
    error: null,
    time: 0,
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.step = STATE_STEP.LOADING;
      state.namespace = payload.namespace;
      state.showOnlyStatus = payload.showOnlyStatus;
    },
    setReady: (state) => {
      state.step = STATE_STEP.READY;
    },
    setError: (state, action) => {
      state.step = STATE_STEP.ERROR;
      state.error = action.payload;
    },
    setTime: (state, { payload }) => {
      state.time = payload.time;
    },
  },
});

const { setLoading, setReady, setError, setTime } = appStateSlice.actions;

const loadShop = async (namespace, dispatch) => {
  const script = document.getElementById("jsonEmbedded");
  const loja = script
    ? JSON.parse(script.innerHTML)
    : await getFirebaseData(`/${namespace}/loja`);
  const shop = sliceFirebaseShop(loja);
  dispatch(setShopDetails(shop));
};

const loadProducts = async (namespace, isInLoco, dispatch, getState) => {
  const orderType = isInLoco ? "mesa" : "entrega";
  const data = await getFirebaseData(`/${namespace}/${orderType}`);
  const parsed = {
    groups: sliceFirebaseGroups(data.grupos),
    items: sliceFirebaseProducts(data.produtos),
  };
  await dispatch(setGroupedProductsWithQuestions(parsed));
  await dispatch(
    setOrderAvailability({ isAvailableToOrder: data["podePedir"] })
  );

  const orders = getState().shoppingCart.productOrders.filter(
    (o) => o.commited
  );
  for (const o of orders) {
    await dispatch(requireProducts(o.productId));
  }

  await dispatch(refreshCachedCosts());
  await dispatch(validateCache());
};

export const fetchAppEssentials =
  (namespace, isInLoco, showOnlyStatus) => async (dispatch, getState) => {
    const isMarketplace = window.location.pathname.includes("marketplace");

    const calmDispatch = minimalDelayedHOC(dispatch, 1200);

    dispatch(
      setLoading({
        namespace,
        showOnlyStatus,
      })
    );

    try {
      await loadShop(namespace, dispatch);

      if (isMarketplace) {
        await loadMarketplace(namespace, dispatch);
      } else {
        if (!showOnlyStatus) {
          await loadProducts(namespace, isInLoco, dispatch, getState);
        }
      }

      calmDispatch(setReady());
    } catch (error) {
      calmDispatch(setError(error));
    }
  };

export const fetchAppTime = (namespace) => async (dispatch, getState) => {
  const time = await getFirebaseData(`/${namespace}/publicado/time`);

  const lastTime = getState().appState.time;
  dispatch(setTime({ time }));

  if (lastTime > 0 && time > lastTime) {
    const loja = await getFirebaseData(`/${namespace}/loja`);
    const shop = sliceFirebaseShop(loja);
    dispatch(setShopDetails(shop));

    await loadProducts(namespace, true, dispatch, getState);
  }
};

export default appStateSlice.reducer;
