import { API_PALLAS_APPS_URL } from "../ducks/consts";

export function billRequest(shopId, tableId) {
  return fetch(`${API_PALLAS_APPS_URL}/requestBill`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      lojaId: shopId,
      location: tableId,
    }),
  });
}

export function waiterRequest(shopId, tableId) {
  return fetch(`${API_PALLAS_APPS_URL}/requestWaiter`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      lojaId: shopId,
      location: tableId,
    }),
  });
}
