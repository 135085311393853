import { API_PALLAS_APPS_URL } from '../ducks/consts';
import { notifyErrorTracker } from './error-tracker';

const RemoteOrder = {
  retrieveOrder: async (shopId, shortReference) => {
    // Trying to get intermittent error. Please remove this after it's fixed.
    const timer = setTimeout(() => notifyErrorTracker(new Error(`Timeout on retrieveOrder - order shortReference: ${shortReference}`)), 15000);
    const response = await fetch(`${API_PALLAS_APPS_URL}/pedido/detalhes`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lojaId: shopId,
        shortReference
      })
    });
    if (!response.ok) {
      // Trying to get intermittent error. Please remove this after it's fixed.
      clearTimeout(timer);
      throw new Error("No 2XX response, left unhandled.");
    }

    const json = await response.json();
    if (!json || typeof(json) !== 'object') {
      // Trying to get intermittent error. Please remove this after it's fixed.
      clearTimeout(timer);
      throw new Error("Unknown JSON response.");
    }

    // Trying to get intermittent error. Please remove this after it's fixed.
    clearTimeout(timer);

    return json;
  },
  retrieveStatus: async (orderId) => {
    // Trying to get intermittent error. Please remove this after it's fixed.
    const timer = setTimeout(() => notifyErrorTracker(new Error(`Timeout on retrieveStatus - order ID: ${orderId}`)), 15000);

    const response = await fetch(`${API_PALLAS_APPS_URL}/pedido/${orderId}/status`);
    if (!response.ok) {
      // Trying to get intermittent error. Please remove this after it's fixed.
    clearTimeout(timer);
      throw new Error('No 2XX response, left unhandled.');
    }

    const json = await response.json();
    if (!json || !Array.isArray(json)) {
      // Trying to get intermittent error. Please remove this after it's fixed.
      clearTimeout(timer);
      throw new Error("Unknown JSON response.");
    }

    // Trying to get intermittent error. Please remove this after it's fixed.
    clearTimeout(timer);

    return json;
  },
};

export default RemoteOrder;
