import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { billRequest } from "../services/waiter";

function RequestBillButton({ shopId, tableId }) {
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);

  const onBillRequest = async (event) => {
    try {
      setLoading(true);
      const response = await billRequest(shopId, tableId);
      if (response.ok) {
        setStatus(true);
      } else {
        setStatus(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onCloseModal = (event) => setStatus(null);

  return (
    <>
      <Button
        className="my-3 p-3"
        variant="outline-dark"
        onClick={onBillRequest}
        disabled={loading}
      >
        {loading ? (
          <Spinner animation="border" size="sm" className="mr-2" />
        ) : (
          <ReceiptIcon className="mr-2" />
        )}
        Pedir a conta
      </Button>
      <Modal centered show={status !== null} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Pedir a conta
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {status === true && (
            <h5 className="p-3">
              Notificamos um Garçom da nossa equipe.
              <br />
              Em breve ele trará o fechamento da conta.
            </h5>
          )}
          {status === false && (
            <h5 className="p-3">
              Opss...
              <br />
              Ocorreu um erro ao chamar o Garçom.
            </h5>
          )}

          <Button
            className="my-3 p-3"
            variant="outline-dark"
            onClick={onCloseModal}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RequestBillButton;
